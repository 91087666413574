import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useNavigate } from 'react-router-dom';

import '../styles/AdminCalendar.css'; // Import your custom CSS
import CustomModal from '../components/CustomModal'; // Assure-toi d'importer ton composant modal

const Admin = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const correctPassword = "test";

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    if (!isAuthenticated) {
      navigate('/admin-login');
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      fetch('/bookings')
        .then(response => response.json())
        .then(data => {
          setBookings(data);
          setFilteredBookings(data); // Initialize filtered bookings
        })
        .catch(error => console.error('Error fetching bookings:', error));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const lowercasedTerm = searchTerm.toLowerCase();
    setFilteredBookings(
      bookings.filter(
        booking =>
          booking.firstName.toLowerCase().includes(lowercasedTerm) ||
          booking.lastName.toLowerCase().includes(lowercasedTerm) ||
          booking.email.toLowerCase().includes(lowercasedTerm) ||
          booking.phone.toLowerCase().includes(lowercasedTerm)
      )
    );
  }, [searchTerm, bookings]);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setShowModal(true);
  };

  const handleCancelBooking = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir annuler cette réservation?")) {
      fetch(`/bookings/${id}`, { method: 'DELETE' })
        .then(response => {
          if (response.ok) {
            // Update the state to remove the booking from the list
            const updatedBookings = bookings.filter(booking => booking.id !== id);
            setBookings(updatedBookings);
            setFilteredBookings(updatedBookings); // Ensure the filtered bookings also update
            setSelectedEvent(null);
            setShowModal(false);
            fetch('/bookings')
        .then(response => response.json())
        .then(data => {
          setBookings(data);
          setFilteredBookings(data); // Initialize filtered bookings
        })
        .catch(error => console.error('Error fetching bookings:', error));
          }
        })
        .catch(error => console.error('Error cancelling booking:', error));
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Save authentication status
      navigate('/admin');
    } else {
      alert("Mot de passe incorrect");
    }
  };

  const events = filteredBookings.map(booking => ({
    title: `${booking.firstName} ${booking.lastName} - ${booking.service}`,
    start: new Date(booking.date),
    end: new Date(new Date(booking.date).getTime() + 60 * 60 * 1000), // Assuming 1 hour per booking
    id: booking.id,
    extendedProps: {
      email: booking.email,
      phone: booking.phone
    }
  }));

  return (
    <>
      {!isAuthenticated ? (
        <div style={styles.loginContainer}>
          <form onSubmit={handleLogin} style={styles.loginForm}>
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Entrez le mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
            />
            <button type="submit" style={styles.button}>
              Se connecter
            </button>
          </form>
        </div>
      ) : (
        <div style={styles.container}>
          <h1 style={styles.header}>Admin - Gestion des Réservations</h1>

          <input
            type="text"
            placeholder="Rechercher par nom, email..."
            style={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div style={styles.calendarContainer}>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events}
              eventClick={handleEventClick}
              headerToolbar={{
                left: 'today',
                center: 'title',
                right: 'prev,next'
              }}
              themeSystem="bootstrap"
              locale="fr"
              
            />
          </div>

          {selectedEvent && (
            <CustomModal
              show={showModal}
              onHide={() => setShowModal(false)}
              title="Détails de la Réservation"
              body={
                <>
                  <p><strong>Service:</strong> {selectedEvent.title}</p>
                  <p><strong>Date:</strong> {new Date(selectedEvent.start).toLocaleString()}</p>
                  <p><strong>Email:</strong> {selectedEvent.extendedProps.email}</p>
                  <p><strong>Téléphone:</strong> {selectedEvent.extendedProps.phone}</p>
                </>
              }
              footer={
                <>
                  <button onClick={() => setShowModal(false)} style={styles.button}>
                    Fermer
                  </button>
                  <button onClick={() => handleCancelBooking(selectedEvent.id)} style={styles.cancelButton}>
                    Annuler la Réservation
                  </button>
                </>
              }
            />
          )}
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  searchInput: {
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
    width: "100%"
  },
  calendarContainer: {
    marginBottom: '20px',
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
  },
  loginForm: {
    width: '300px',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    fontSize: '16px',
    width: '100%',
  },
  button: {
    backgroundColor: '#ff3dc1',
    borderColor: '#ff3dc1',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
  },
  cancelButton: {
    backgroundColor: 'red',
    borderColor: 'red',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: "100%"
  }
};

export default Admin;
