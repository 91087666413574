import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';

const AdminLogin = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const correctPassword = "test";

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      navigate('/admin');
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      alert("Mot de passe incorrect");
    }
  };

  return (
    <div style={styles.loginContainer}>
      <Form onSubmit={handleLogin}>
        <Form.Group controlId="formPassword">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            type="password"
            placeholder="Entrez le mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={styles.button}>
          Se connecter
        </Button>
      </Form>
    </div>
  );
};

const styles = {
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
  },
  button: {
    backgroundColor: '#ff3dc1',
    borderColor: '#ff3dc1',
  },
};

export default AdminLogin;
