import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Importation des styles de base
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Modal from "../components/Modal"; // Assurez-vous que le chemin est correct
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap

const Booking = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [cancellationEmail, setCancellationEmail] = useState("");
  const [userBookings, setUserBookings] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  useEffect(() => {
    fetch("/bookings")
      .then((response) => response.json())
      .then((data) => {
        setBookings(data);
        setBookedSlots(data.map((b) => new Date(b.date + "T" + b.time)));
      });
  }, []);

  useEffect(() => {
    fetch("/unavailable-dates")
      .then((response) => response.json())
      .then((data) => setUnavailableDates(data.map((date) => new Date(date))));
  }, []);

  const handleShowCancellationModal = () => {
    if (cancellationEmail.length === 0) {
      return;
    } else {
      fetch(`/bookings?email=${cancellationEmail}`)
        .then((response) => response.json())
        .then((data) => {
          setUserBookings(data);
          setShowCancellationModal(true);
        });
    }
  };

  const serviceDurations = {
    "Nettoyage de votre vélo": 60, // en minutes
    "Purge de frein": 45,
    "Entretien des freins": 45,
    "Réglage complet du vélo": 90,
  };

  const getWorkHours = (day) => {
    const isWeekend = day.getDay() === 0 || day.getDay() === 6; // Samedi ou Dimanche
    const start = isWeekend ? 10 : 8;
    const end = isWeekend ? (day.getDay() === 0 ? 20 : 21) : 21;

    return { start, end };
  };

  const generateTimeSlots = (day) => {
    if (!isDateAvailable(day)) {
      return []; // Pas de créneaux disponibles si la date n'est pas disponible
    }

    const { start, end } = getWorkHours(day);
    const timeSlots = [];
    let startTime = new Date(day.setHours(start, 0));
    const endTime = new Date(day.setHours(end, 0));

    while (startTime < endTime) {
      timeSlots.push(new Date(startTime));
      startTime.setMinutes(startTime.getMinutes() + 30); // Créneaux de 30 minutes
    }

    return timeSlots;
  };

  const getAvailableSlots = (slots) => {
    if (!selectedService || !selectedDate) return slots;
    const duration = serviceDurations[selectedService];
    const slotsWithDuration = [];

    for (let i = 0; i < slots.length; i++) {
      const startSlot = slots[i];
      const endSlot = new Date(startSlot.getTime());
      endSlot.setMinutes(endSlot.getMinutes() + duration);

      if (
        isSlotAvailable(startSlot) &&
        !bookedSlots.some(
          (bookedSlot) =>
            bookedSlot.getTime() >= startSlot.getTime() &&
            bookedSlot.getTime() < endSlot.getTime()
        )
      ) {
        let slotAvailable = true;
        for (let j = 1; j < duration / 30; j++) {
          const nextSlot = new Date(startSlot.getTime());
          nextSlot.setMinutes(nextSlot.getMinutes() + 30 * j);
          if (
            !isSlotAvailable(nextSlot) ||
            bookedSlots.some(
              (bookedSlot) =>
                bookedSlot.getTime() >= nextSlot.getTime() &&
                bookedSlot.getTime() < nextSlot.getTime() + 30 * 60000
            )
          ) {
            slotAvailable = false;
            break;
          }
        }

        if (slotAvailable) {
          slotsWithDuration.push(startSlot);
        }
      }
    }

    return slotsWithDuration;
  };

  const isSlotAvailable = (slot) => {
    return (
      slot &&
      !bookedSlots.some((bookedSlot) => bookedSlot.getTime() === slot.getTime())
    );
  };

  const isDateAvailable = (date) => {
    return !unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );
  };

  const handleCloseCancellationModal = () => {
    setShowCancellationModal(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Réinitialiser l'heure sélectionnée lorsque la date change
  };

  const handleTimeSlotClick = (slot) => {
    if (isSlotAvailable(slot)) {
      setSelectedTime(
        selectedTime && selectedTime.getTime() === slot.getTime() ? null : slot
      ); // Toggle selection
    }
  };

  const handleRemoveTimeSlot = () => {
    setSelectedTime(null);
  };

  const handleBooking = (event) => {
    event.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !selectedService ||
      !selectedDate ||
      !selectedTime ||
      !phone
    ) {
      setErrorMessage("Veuillez remplir tous les champs du formulaire.");
      return;
    }

    // Validation des dates
    if (!isValidDate(selectedDate) || !isValidDate(selectedTime)) {
      setErrorMessage("Date ou heure sélectionnée est invalide.");
      return;
    }

    const duration = serviceDurations[selectedService];
    const endTime = new Date(selectedTime.getTime());
    endTime.setMinutes(endTime.getMinutes() + duration);

    if (
      bookings.some(
        (b) =>
          b.service === selectedService &&
          b.date === format(selectedDate, "yyyy-MM-dd") &&
          b.time === format(selectedTime, "HH:mm:ss")
      )
    ) {
      setErrorMessage("Vous avez déjà réservé ce service pour ce créneau.");
      return;
    }

    // Vérifier si tous les créneaux nécessaires sont disponibles
    let currentSlot = new Date(selectedTime);
    const slotsToBook = [];
    while (currentSlot < endTime) {
      if (
        isSlotAvailable(currentSlot) &&
        !bookedSlots.some(
          (bookedSlot) =>
            bookedSlot.getTime() >= currentSlot.getTime() &&
            bookedSlot.getTime() < currentSlot.getTime() + 30 * 60000
        )
      ) {
        slotsToBook.push(new Date(currentSlot));
        currentSlot.setMinutes(currentSlot.getMinutes() + 30);
      } else {
        setErrorMessage(
          "Un ou plusieurs créneaux nécessaires pour cette réservation ne sont pas disponibles."
        );
        return;
      }
    }

    fetch("/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        service: selectedService,
        date: format(selectedDate, "yyyy-MM-dd"),
        time: format(selectedTime, "HH:mm:ss"),
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        slots: slotsToBook.map((slot) => format(slot, "HH:mm:ss")),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Booking successful:", data);
        setBookings([
          ...bookings,
          {
            service: selectedService,
            date: format(selectedDate, "yyyy-MM-dd"),
            time: format(selectedTime, "HH:mm:ss"),
          },
        ]);
        setBookedSlots([...bookedSlots, ...slotsToBook]);
        setSelectedTime(null); // Réinitialiser l'heure sélectionnée après la réservation
        setSelectedDate(null); // Réinitialiser la date sélectionnée après la réservation
        setSelectedService("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setErrorMessage("");
        setModalMessage(
          "Votre demande de réservation a été envoyée avec succès !"
        ); // Message de succès
        setShowModal(true); // Afficher le modal
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Erreur lors de la réservation.");
      });
  };

  const handleCancelBooking = (bookingId) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir annuler cette réservation ?")
    ) {
      fetch(`/bookings/${bookingId}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then(() => {
          setUserBookings(
            userBookings.filter((booking) => booking.id !== bookingId)
          );
          alert("La réservation a été annulée avec succès.");
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Erreur lors de l'annulation de la réservation.");
        });
    }
  };

  const isValidDate = (date) => date instanceof Date && !isNaN(date);

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      borderRadius: "8px",
      backgroundColor: "#fff",
    },
    header: {
      textAlign: "center",
      color: "#333",
      paddingTop: "50px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    label: {
      fontWeight: "bold",
    },
    input: {
      padding: "10px",
      borderRadius: "4px",
      border: "1px solid #ddd",
    },
    select: {
      padding: "10px",
      borderRadius: "4px",
      border: "1px solid #ddd",
      backgroundColor: "#fff",
    },
    button: {
      padding: "10px 20px",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#ff3dc1",
      color: "#fff",
      cursor: "pointer",
      fontSize: "16px",
      width: "100%",
    },
    errorMessage: {
      color: "red",
      textAlign: "center",
    },
    successMessage: {
      color: "green",
      textAlign: "center",
    },
    timeSlotsContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      justifyContent: "center",
      marginTop: "20px",
    },
    timeSlot: {
      padding: "10px 20px",
      borderRadius: "4px",
      border: `1px solid #ff3dc1`, // Couleur d'accent
      backgroundColor: "#f0f8ff",
      cursor: "pointer",
      fontSize: "16px",
      textAlign: "center",
    },
    selectedTimeSlot: {
      backgroundColor: "#ff3dc1", // Couleur d'accent
      color: "#fff",
    },
    removeButton: {
      padding: "10px",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#dc3545",
      color: "#fff",
      cursor: "pointer",
      fontSize: "14px",
      marginBottom: "20px",
    },
    calendarContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
    },
    modalOverlay: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "9999",
    },
    modalContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "500px",
      textAlign: "center",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    },
  };

  return (
    <div style={styles.container} className="container">
      <h2 style={styles.header}>Réserver un rendez-vous</h2>
      <form onSubmit={handleBooking} style={styles.form}>
        <div>
          <label htmlFor="firstName" style={styles.label}>
            Prénom:
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={styles.input}
            className="form-control"
          />
        </div>
        <div>
          <label htmlFor="lastName" style={styles.label}>
            Nom:
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={styles.input}
            className="form-control"
          />
        </div>
        <div>
          <label htmlFor="email" style={styles.label}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            className="form-control"
          />
        </div>
        <div>
          <label htmlFor="phone" style={styles.label}>
            Téléphone:
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={styles.input}
            className="form-control"
          />
        </div>
        <div>
          <label htmlFor="service" style={styles.label}>
            Service:
          </label>
          <select
            id="service"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            style={styles.select}
            className="form-select"
          >
            <option value="">Sélectionnez un service</option>
            <option value="Nettoyage">Nettoyage de votre vélo</option>
            <option value="Purge">Purge de frein</option>
            <option value="Entretien">Entretien des freins</option>
            <option value="Reglage">Réglage complet du vélo</option>
          </select>
        </div>
        <div style={styles.calendarContainer}>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            locale="fr"
            minDate={new Date()}
            tileDisabled={({ date }) =>
              !isDateAvailable(date) || generateTimeSlots(date).length === 0
            }
            className="react-calendar"
          />
        </div>
        {selectedDate && (
          <>
            <h3 style={styles.header}>Sélectionnez un créneau horaire</h3>
            <div style={styles.timeSlotsContainer}>
              {getAvailableSlots(generateTimeSlots(new Date(selectedDate))).map(
                (slot, index) => (
                  <div
                    key={index}
                    style={{
                      ...styles.timeSlot,
                      ...(selectedTime &&
                      selectedTime.getTime() === slot.getTime()
                        ? styles.selectedTimeSlot
                        : {}),
                    }}
                    onClick={() => handleTimeSlotClick(slot)}
                  >
                    {format(slot, "HH:mm")}
                  </div>
                )
              )}
            </div>
          </>
        )}
        {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
        <button type="submit" style={styles.button}>
          Réserver
        </button>
      </form>

      <h3 style={styles.header}>Annulation de réservation</h3>
      <div>
        <input
          type="email"
          placeholder="Entrez votre email"
          value={cancellationEmail}
          onChange={(e) => setCancellationEmail(e.target.value)}
          style={styles.input}
          className="form-control"
        />
        <button onClick={handleShowCancellationModal} style={styles.button}>
          Voir les réservations
        </button>
      </div>

      {showCancellationModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h4>Vos réservations</h4>
            {userBookings.length > 0 ? (
              userBookings.map((booking) => (
                <div key={booking.id}>
                  <p>
                    {booking.service} le {booking.date} à {booking.time}
                  </p>
                  <button
                    onClick={() => handleCancelBooking(booking.id)}
                    style={styles.removeButton}
                  >
                    Annuler
                  </button>
                </div>
              ))
            ) : (
              <p>Aucune réservation trouvée.</p>
            )}
            <button
              onClick={handleCloseCancellationModal}
              style={styles.button}
            >
              Fermer
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <p>{modalMessage}</p>
            <button onClick={() => setShowModal(false)} style={styles.button}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Booking;
