import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Booking from './pages/Booking';
import Admin from './pages/Admin';
import AdminLogin from './pages/AdminLogin';


function App() {
  return (
    <Router>

            <Routes>
                <Route path="/" element={<Booking />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin-login" element={<AdminLogin />} />
            </Routes>
        </Router>
  );
}

export default App;