import React from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Assure-toi que Bootstrap est importé

const CustomModal = ({ show, onHide, title, body, footer }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="custom-modal-dialog" // Class pour le style personnalisé
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        {footer}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
